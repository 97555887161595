import './blog.css'

const Blog = () => {
	return (
		<div className='blog'>
			<div className='blog__box'>Скоро ...</div>
		</div>
	)
}

export default Blog
